$brand-orange: rgb(255, 114, 1);
$brand-blue: rgb(39, 66, 135);
$brand-grey: rgb(57, 57, 57);
$brand-black: #000000;
$brand-white: #ffffff;

$border: 0px;

body {
    overflow-x: hidden;
    max-width: 100%;
}

h1 {
    font-size: 44px;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

h2 {
    font-size: 40px;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

h3 {
    font-size: 36px;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

h4 {
    font-size: 32px;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

h5 {
    font-size: 28px;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

h6 {
    font-size: 24px;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

p {
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@media (max-width: 992px) {
    h1 {
        font-size: 40px;
    }
    
    h2 {
        font-size: 36px;
    }
    
    h3 {
        font-size: 32px;
    }
    
    h4 {
        font-size: 28px;
    }
    
    h5 {
        font-size: 24px;
    }
    
    h6 {
        font-size: 20px;
    } 

    p {
        font-size: 16px;
    }
}

a {
    color: $brand-orange;
    text-decoration: none;
    &:hover {
        opacity: .75;
        color: $brand-orange;
        text-decoration: none;
    }
}

.social-media-menu {
    background-color: $brand-blue;
    width: 100%;
    height: 42px;
    position: relative;
    z-index: 9;
    ul {
        float: right;
        margin: 6px 0 6px 0;
        li {
            display: inline-block;
            line-height: 35px;
            margin-left: 5px;
            margin-right: 5px;
            width: 28px;
            height: 28px;
            a i {
                color: white;
                font-size: 25px;
            }
        }
    }
}

.navbar {
    background: transparent;
    height: 70px;
    width: 100%;
    z-index: 10;
    transition: background-color .5s;
    transition: position .5s;
}

.nav-alt {
    background: $brand-grey;
}

.nav-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .nav-container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .nav-container {
        max-width: 720px;
    }
}

@media (max-width: 991px) {
    .fixed-top {
        position: relative;
        top: 0;
        z-index: 10;
    }
    .nav-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .navbar {
        background: $brand-grey;
    }
    .navbar-collapse {
        background-color: $brand-orange;
        margin-top: -3px;
        margin-left: -145px;
        padding-left: 145px;
        margin-right: -145px;
        overflow: hidden;
    }
}

@media (min-width: 992px) {
    .nav-container {
        max-width: 960px;
    }

    .navbar-expand-lg>.nav-container {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .fixed-top {
        background-color: $brand-grey;
        transition: all .5s;
    }

    .navbar-dark .navbar-nav .nav-link:hover {
        color: $brand-white;
    }
    
    .navbar-dark .navbar-nav .nav-link:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: 0;
        left: 0;
        background-color: $brand-orange;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    
    .navbar-dark .navbar-nav .nav-link:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@media (min-width: 1200px) {
    .nav-container {
        max-width: 1140px;
    }
}

.navbar>.nav-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.nav-item {
    margin-left: 25px;
}

.navbar-dark .navbar-nav .nav-link {
    font-size: 1.2rem;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
    color: $brand-white;
    position: relative;
    text-decoration: none;
    &:hover {
        opacity: 1.0;
    }
}
  
.logo {
    max-width: 250px;
}

.navbar-brand:hover {
    opacity: 1.0;
}

header {
    width: 100%;
    height: 80vh;
    background-color: $brand-blue;
    position: absolute;
    top: 40px;
    overflow: hidden;
    border-bottom: $border solid $brand-orange;
}

.heroVideo {
    width: 110%;
    position: absolute;
    min-height: 80vh;
    bottom: 0;
    left: -5%;
}

@media (max-width: 1190px) {
    .heroVideo {
        width: 150%;
        left: -25%;
    }
}

@media (max-width: 895px) {
    .heroVideo {
        width: 170%;
        left: -35%;
    }
}

@media (max-width: 767px) {
    .heroVideo {
        width: 220%;
        left: -60%;
    }
    header {
        height: 60vh;
    }
}

@media (max-width: 592px) {
    .heroVideo {
        width: 275%;
        left: -87.5%;
    }
}

@media (max-width: 480px) {
    .heroVideo {
        width: 320%;
        left: -110%;
    }
}

.overlay {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    background-color: $brand-grey;
    opacity: .5;
}

.tagline {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
    h1 {
        position: relative;
        color: $brand-white;
        text-align: center;
        top: 48%;
        transform: translateY(-50%);
        font-size: 4rem;
        font-weight: 700;
    }
}

@media (max-width: 991px) {
    .tagline h1 {
        font-size: 3rem;
    }
}

.page-content {
    top: 85vh;
    position: absolute;
    width: 100%;
}

@media (max-width: 767px) {
    .page-content {
        top: 65vh;
    }
}

.content-p {
    padding: 40px 0 40px 0;
}

.features {
    height: 700px;
    background-image: url('../img/header_img/about.jpg');
    background-size: cover;
    background-position: center 40%;
    width: 100%;
    border-top: solid $border $brand-blue;
    border-bottom: solid $border $brand-orange;
}

.team {
    height: 700px;
    background-image: url('../img/team2019/team2019.jpg');
    background-size: cover;
    background-position: center 40%;
    width: 100%;
    border-top: solid $border $brand-blue;
    border-bottom: solid $border $brand-orange;
}

.google-map {
    width: 100%;
    height: 500px;
    overflow: hidden;
    border-top: solid $border $brand-blue;
    border-bottom: solid $border $brand-orange;
}

.media {
    height: 700px;
    background-image: url('../img/header_img/last.jpg');
    background-size: cover;
    background-position: center 40%;
    width: 100%;
    border-top: solid $border $brand-blue;
    border-bottom: solid $border $brand-orange;
}

.sponsors {
    width: 100%;
    height: 700px;
    background-image: url('../img/sponsors.jpg');
    background-size: cover;
    background-position: center 30%;
    overflow: hidden;
    border-top: solid $border $brand-blue;
    border-bottom: solid $border $brand-orange;
}

.join {
    width: 100%;
    height: 700px;
    background-image: url('../img/header_img/join.jpg');
    background-size: cover;
    background-position: center 34%;
    overflow: hidden;
    border-top: solid $border $brand-blue;
    border-bottom: solid $border $brand-orange;  
}

.elite-sponsors {
    width: 100%;
    height: auto;
    background-color: $brand-blue;
    .content-p {
        opacity: .5;;
    }
}

footer {
    width: 100%;
    height: auto;
    background-color: $brand-grey;
    color: $brand-white;
    text-align: center;
    .content-p {
        // padding-bottom: 20px;
    }
    p {
        margin-bottom: 0px;
        font-size: 16px;
    }
    .copyright {
        font-size: 14px;
        // margin-top: 20px;
    }
    hr {
        width: 100%;
        border: solid .2px $brand-white;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

// Page - Template Styles

.page-intro {
    height: 35vh;
    width: 100%;
    background-image: linear-gradient(rgba(204, 132, 72, .25), rgba(204, 132, 72, .25)), url('../img/header_img/about.jpg');
    background-size: cover;
    background-position: center 30%;
    .page-title {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        h1 {
            text-align: center;
            color: $brand-white;
        }
    }
}

.join-intro {
    background-image: linear-gradient(rgba(204, 132, 72, .25), rgba(204, 132, 72, .25)), url('../img/header_img/join.jpg');
    background-position: center 60%;
}

.about-intro {
    background-image: linear-gradient(rgba(204, 132, 72, .25), rgba(204, 132, 72, .25)), url('../img/header_img/about.jpg');
}

.media-intro {
    background-image: linear-gradient(rgba(204, 132, 72, .25), rgba(204, 132, 72, .25)), url('../img/header_img/last.jpg');
    background-position: center 45%;
}

.sponsors-intro {
    background-image: linear-gradient(rgba(204, 132, 72, .25), rgba(204, 132, 72, .25)), url('../img/sponsors.jpg');
    background-position: center 25%;
}

.page-content-norm {
    top: 112px;
    position: absolute;
    width: 100%;
}

.col-centered {
    display: inline-block;
    float: none;
    text-align: center;
    margin-right: -4px;
}

.row-centered {
    text-align: center;
    padding: 10px 0 10px 0;
}

.sponsor {
        a:hover {
            opacity: 1.0;
        }
        img {
        width: 155px;
        height: auto;
    }
}

.btn-primary {
    background: $brand-orange;
    border-color: $brand-orange;
    &:hover {
        background: $brand-orange;
        border-color: $brand-orange;
    }
}

.headshot {
    width: 100%;
    display: inline-block;
    position: relative;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
        height: auto;
        border-radius: 100%;
        border: double 6px $brand-blue;
    }
}

.details-block {
    margin-top: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    h4 {
        font-size: 18px;
    }
    p {
        font-size: 14px;
    }
    button {
        margin-bottom: 40px;
    }
}

.card-img-top {
    max-height: 165px;
}

@media (max-width: 1200px) and (min-width: 992px) {
    .headshot img {
        width: 200px;
        height: auto;
        border-radius: 100%;
        border: double 6px $brand-blue;
    }
}